exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/{MarkdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-site-status-tsx": () => import("./../../../src/pages/site-status.tsx" /* webpackChunkName: "component---src-pages-site-status-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */)
}

